<template>
  <div class="row justify-content-center">
    <!-- loader -->
    <div id="loader" style="display: none">
<!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
<!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-md-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <img src="../assets/img/0001.jpg" width="180px"/>
      </div>
      <div class="section mb-5 p-2">

        <form action="index.html">
          <div class="card">
            <div class="card-body pb-1">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="email1">Phone Number</label>
                  <input v-model="phone" type="email" class="form-control" id="email1" :class="{ error: v$.phone.$errors.length }" placeholder="Enter Your Phone Number">
                  <i class="clear-input">
<!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.phone.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="submitPhone" :disabled="!phone"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Continue</span></button>
              </div>
            </div>
          </div>


<!--          <div class="form-links mt-2">
            <div>
              <router-link to="/member-registration">Register as New Member</router-link>
            </div>
          </div>-->



        </form>
      </div>

    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, numeric } from '@vuelidate/validators'
export default {
  name: 'Login',
  data() {
    return {
      phone: '',
      isWaitingResponse: false
    }
  },
  mounted() {
    console.log(process.env)
    localStorage.setItem('LoggedUser', false)
  },
  validations () {
    return {
      phone: {
        required,
        minLength: minLength(10),
        numeric
      }
    }
  },
  components: {
  },
  methods: {
    submitPhone() {
      this.v$.$validate()
      if(this.v$.$error) return
      this.isWaitingResponse = true
      this.$axios.post('otp-generate', {
        phone_number: this.phone,
        client_name: process.env.VUE_APP_CLIENT_NAME,
        sms_otp_template_id: process.env.VUE_APP_SMS_TEMPLATE_ID
      }).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 1) {
          this.$router.push({
            path: '/otp-verification',
            query: {
              phone: this.phone
            }
          })
        }
      })
    }
  },
  setup: () => {
    return { v$: useVuelidate() }
  },
}
</script>

<style scoped>

</style>
